<template>
  <form-dialog
    :title="`حجز وقت دخول للقاعة`"
    ref="form"
    :dialog.sync="dialog"
    :load="loadDialog"
    @close="$emit('close')"
    :readonly="readonly"
    @submit="$emit('submit')"
    width="400px"
  >
    <template v-slot:content>
      <v-row>
        <v-col class="d-flex" cols="12" sm="12">
          <v-select
            :items="accounts"
            v-model="form.account_id"
            item-text="name"
            item-value="id"
            label="الحساب"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-select
            :items="activities"
            v-model="activity_id"
            item-text="title"
            item-value="id"
            label="النشاط"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="form.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                label="اختر التاريخ"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              :min="moment.format('YYYY-MM-DD')"
              scrollable
              :max="max"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> اغلاق </v-btn>
              <v-btn text color="primary" @click="submit(0)"> تأكيد </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        
        <v-col class="d-flex" cols="12" sm="12">
          <v-select
            :items="activity_times"
            v-model="form.activity_time_id"
            item-text="name"
            item-value="id"
            label=""
          >
            <template v-slot:item="{ item }">
              {{item.from}} - المشرفة :  {{item.supervisor_name}}
            </template>
            <template v-slot:selection="{ item }">
              {{item.from}} - المشرفة :  {{item.supervisor_name}}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </template>
  </form-dialog>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import FormDialog from "../../../components/customs/form-dialog.vue";
export default {
  components: { FormDialog },
  props: {
    dialog: Boolean,
    readonly: Boolean,
    edit: Boolean,
    loadDialog: Boolean,
  },
  mounted() {},
  data() {
    return {
      modal: false,
      activity_id: null,
    };
  },
  computed: {
    ...mapGetters("Reservations", ["activities", "form", "activity_times",'accounts']),
    max() {
      return moment().add("days", 7).format("YYYY-MM-DD");
    },
    moment() {
      return moment();
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    submit(flag) {
      this.filter({
        type: 2,
        date: this.form.date,
        activity_id: this.activity_id,
      })
        .then((res) => {
          console.log(res);
          if (flag == 0) {
            this.$refs.dialog.save(this.form.date);
          } else if (flag == 1) {
            this.$refs.dialog1.save(this.form.time);
          }
        })
        .catch((err) => {
          const { message } = err.response.data;
          this.send_error(message);
        });
    },
    ...mapActions("Reservations", ["create", "clear", "filter"]),
  },
  watch: {
    dialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        // this.clear();
      }
      if (this.dialog) {
        this.clear({type:3})
        this.submit(0);
      }
    },
  },
};
</script>

<style>
</style>